<template>
  <div>
    <b-form-checkbox
      @click.native="popupActive = true"
      value="1"
      unchecked-value="2"
      v-model="form.budget_active"
      switch
      class="table-status-icon"
    ></b-form-checkbox>

    <div class="demo-alignment">
      <vs-popup
        :id="'popmodalS' + params.data.budget_id"
        :button-close-hidden="true"
        class="holamundo p-2"
        title="Budget Status"
        :active.sync="popupActive"
      >
        <div class="text-center">
          <h5>Do you want to change Status ?</h5>
          <!-- SUBMIT BUTTON -->
          <div class="vx-row mt-5">
            <div class="vx-col mx-auto">
              <!-- :disabled="submitStatus" -->
              <vs-button
                color="success"
                type="filled"
                @click="onChangeCheckbox($event)"
                class="mr-3"
                >Yes</vs-button
              >

              <vs-button color="danger" type="filled" @click="privStatus()"
                >No</vs-button
              >
            </div>
          </div>
        </div>
      </vs-popup>
    </div>
  </div>
  <!--  -->
</template>

<script>
import Vue from "vue";
import BudgetService from "@/services/budgetService.js";
export default Vue.extend({
  data() {
    return {
      params: null,
      popupActive: false,
      form: {
        budget_active: "1"
      },
      copy_budget_active: null
    };
  },
  beforeMount() {
    this.form = this.params.data;
    this.copy_budget_active = this.form.budget_active;
  },
  computed: {
    projectId() {
      return this.$store.state.budgetObject.projectId;
    }
  },
  beforeDestroy() {
    let _id = document.getElementById("popmodalS" + this.params.data.budget_id);
    console.log("_id", _id);
    _id.setAttribute("style", "display: none;");
  },
  methods: {
    onChangeCheckbox: function(e, value) {
      this.$vs.loading();
      let id = this.params.data.budget_id;
      let payload = {
        budget_id: this.form.budget_id,
        budget_code: this.form.budget_code,
        category_name: this.form.category_name,
        sub_category_name: this.form.sub_category_name,
        budget_amount: this.form.budget_amount,
        actual_amount: this.form.actual_amount,
        budget_active: this.form.budget_active,
        project_id: this.projectId
      };
      BudgetService.editBudget(payload, id)
        .then(response => {
          this.$vs.loading.close();
          const { data } = response;
          if (data.Status) {
            this.$vs.notify({
              time: 5000,
              title: "Updated!",
              text: data.Message,
              iconPack: "feather",
              icon: "check_box",
              color: "success"
            });
            this.popupActive = false;
          } else {
            this.$vs.loading.close();
            this.$vs.notify({
              time: 5000,
              title: "Error!",
              text: data.Message,
              iconPack: "feather",
              icon: "check_box",
              color: "warning"
            });
          }
        })
        .catch(err => {
          this.$vs.loading.close();
        });
    },
    privStatus: function() {
      this.popupActive = false;
      this.form.budget_active = this.copy_budget_active;
    }
  }
});

// import Vue from "vue";

// export default Vue.extend({
//   template: ` {{params.value}}
//          <label class="switch">
//           <input @click="onChangeCheckbox" type="checkbox">
//           <span class="slider round"></span>
//     </label> `,
//   data: function () {
//     return {};
//   },
//   beforeMount() {},
//   mounted() {},
//   methods: {

//   }
// });
</script>
