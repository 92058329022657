<!-- =========================================================================================
   File Name: budgetView.vue
    Description: Budget
    ----------------------------------------------------------------------------------------
    Item Name: KOZO Admin - VueJS Dashboard
    Author:
    Author URL:
========================================================================================== -->

<template>
  <div id="dashboard-analytics">
    <div class="vx-col w-full">
      <router-link to="/apps/projects">
        <feather-icon icon="ArrowLeftCircleIcon" svgClasses="h-8 w-8" />
      </router-link>
    </div>

    <!-- BUDGET FORMS -->

    <!--BUDGET IMPORT FROM EXCEL POPUP -->
    <vs-popup
      :button-close-hidden="false"
      class="holamundo p-2 assigneePopup"
      title="Import Budget from Excel"
      :active.sync="popupActivo"
    >
      <div class="text-center position-relative">
        <form>
          <div class="row">
            <div class="col-8">
              <!-- Start ************* -->
              <div>
                <multiselect
                  v-validate="'required'"
                  v-model="projects_value"
                  track-by="project_id"
                  open-direction="bottom"
                  label="project_name"
                  @input="
                    ({ project_id }) => (this.form.project_id = project_id)
                  "
                  :options="projects"
                  name="Project Name"
                  placeholder="Select Project"
                  :searchable="true"
                  :allow-empty="false"
                  deselect-label="Can't remove this value"
                >
                  <template slot="singleLabel" slot-scope="{ option }">
                    {{ option.project_name }}
                  </template>
                </multiselect>
                <p class="error-msg">
                  <span
                    class="text-danger text-sm"
                    v-show="errors.has('Project Name')"
                    >{{ errors.first("Project Name") }}</span
                  >
                </p>
              </div>
              <!-- ************* End -->
              <div class="row">
                <div class="col-6">
                  <h6>No. of Shoot days</h6>
                  <vs-input
                    type="number"
                    name="GST"
                    v-model="shoot_days"
                    class="w-full"
                    placeholder="Number of shoot days"
                    :disabled="this.form.project_id == null ? true : false"
                  />
                  <p class="error-msg text-sm">
                    <span
                      class="text-danger text-sm"
                      v-show="errors.has('Priority')"
                      >{{ errors.first("Priority") }}</span
                    >
                  </p>
                </div>
                <div class="col-6">
                  <h6>No. of Setting days</h6>
                  <vs-input
                    type="number"
                    name="GST"
                    v-model="setting_days"
                    class="w-full"
                    placeholder=""
                    :disabled="this.form.project_id == null ? true : false"
                  />
                  <p class="error-msg text-sm">
                    <span
                      class="text-danger text-sm"
                      v-show="errors.has('Priority')"
                      >{{ errors.first("Priority") }}</span
                    >
                  </p>
                </div>
              </div>
            </div>

            <div class="col-4">
              <vx-tooltip text="Import Excel" position="left">
                <label for="import-file">
                  <!-- @change="uploadExcel($event)" -->
                  <input
                    :disabled="this.form.project_id == null ? true : false"
                    ref="uploadExcelFile"
                    type="file"
                    @change="uploadExcel($event)"
                    name="import-file"
                    id="import-file"
                    class="d-none"
                  />
                  <!-- class="d-none" -->
                  <div
                    class="vs-component vs-button px-4 vs-button-primary vs-button-filled"
                    :style="
                      this.form.project_id == null
                        ? 'opacity: .5; cursor: default;pointer-events: none;'
                        : ''
                    "
                  >
                    <span class="d-inline-flex pr-5 text-white">Import</span>
                    <img
                      class="d-inline-flex"
                      src="@/assets/images/icons/import-excel.svg"
                      height="20"
                      width="20"
                    />
                  </div>
                </label>
              </vx-tooltip>
            </div>
          </div>

          <!-- SUBMIT BUTTON -->
          <div class="vx-row">
            <div class="vx-col mx-auto">
              <!-- :disabled="submitStatus"
              @click.prevent="submitForm"-->
              <vs-button
                type="filled"
                class="mr-3"
                :disabled="submitStatus"
                @click.prevent="submitExcel"
                >Submit</vs-button
              >
              <!-- <vs-button color="warning" type="border" class @click.prevent="clearForm">Reset</vs-button> -->
            </div>
          </div>
        </form>
      </div>
    </vs-popup>

    <!-- BUDGET SEARCH -->
    <div class="row">
      <div class="col-12">
        <vx-card class="form-title" title="Filter Budget List">
          <div class="col-6 mx-auto">
            <div>
              <multiselect
                v-model="projectSelected"
                track-by="project_id"
                label="project_name"
                :options="projects"
                name="Project Name"
                placeholder="Select Project"
                :searchable="true"
                :allow-empty="false"
                open-direction="bottom"
                deselect-label="Can't remove this value"
              >
                <template slot="singleLabel" slot-scope="{ option }">
                  {{ option.project_name }}
                </template>
              </multiselect>
              <p class="error-msg">
                <span
                  class="text-danger text-sm"
                  v-show="errors.has('Project Name')"
                  >{{ errors.first("Project Name") }}</span
                >
              </p>
            </div>
            <div class="text-center">
              <vs-button
                color="primary"
                class="text-center"
                type="filled"
                @click="searchBudget()"
                >Search</vs-button
              >
            </div>
          </div>

          <div class="vx-col">
            <div class="excel-btn-1">
              <vx-tooltip text="Template Excel" position="left">
                <vs-button
                  @click.native="
                    onClickSampleDownload(
                      `${excelurl}/v2/budget/excel/sample?organization_id=${organization_id}&access_token=${temp_token}`
                    )
                  "
                  type="border"
                  class="py-2"
                >
                  <img
                    class="d-inline-flex"
                    src="@/assets/images/icons/excel.svg"
                    height="20"
                    width="20"
                  />
                </vs-button>
                <!-- </a> -->
              </vx-tooltip>
            </div>

            <div class="excel-btn-2">
              <vs-button
                @click="popupActivo = true"
                class="px-2 py-3"
                color="primary"
                type="border"
              >
                <img
                  class="d-inline-flex mr-2"
                  src="@/assets/images/icons/excel.svg"
                  height="20"
                  width="20"
                  alt
                />Import from Excel
              </vs-button>
            </div>
          </div>
        </vx-card>
      </div>
    </div>
    <div class="vx-col w-full mt-5">
      <p
        style="background-color: white; padding: 4px"
        v-if="projectSelected.shoot_days > 0"
      >
        Shoot Days
        <span style="font-weight: bold">{{ projectSelected.shoot_days }}</span>
        Setting Days
        <span style="font-weight: bold">{{
          projectSelected.setting_days
        }}</span>
      </p>
      <vs-tabs v-model="tabIndex">
        <vs-tab
          value="1"
          label="Budget Report"
          icon-pack="feather"
          icon="icon-box"
        >
          <!-- BUDGET REPORT -->
          <div>
            <budget-report></budget-report>
          </div>
        </vs-tab>
        <vs-tab
          value="0"
          label="Budget List"
          icon-pack="feather"
          icon="icon-box"
        >
          <div class="tab-text">
            <!-- BUDGET LIST -->
            <div>
              <budget-list></budget-list>
            </div>
          </div>
        </vs-tab>
      </vs-tabs>
    </div>

    <!-- <div class="centerx">
      <vs-popup class="holamundo editModal" title="Update Department" :active.sync="popupActive">
        <form>
          <FocusLock>
            <div class="vx-row">
              <div class="col-12">
                <div class="vx-row mb-6">
                  <div class="col-md-4 text-right align-self-center">
                    <h5 class="mb-0">Budget Code</h5>
                  </div>
                  <div class="col-7">
                    <div>
                      <multiselect
                        disabled
                        v-model="company_value"
                        track-by="company_id"
                        label="company_name"
                        @input="({company_id})=> this.form.company_id = company_id"
                        :options="companies"
                        name="Company Name"
                        :searchable="false"
                        :deselectLabel="''"
                        :select-label="''"
                        :allow-empty="false"
                        deselect-label="Can't remove this value"
                      >
                        <template
                          slot="singleLabel"
                          slot-scope="{ option }"
                        >{{ option.company_name }}</template>
                      </multiselect>
                      <span
                        class="text-danger text-sm"
                        v-show="errors.has('Company Name')"
                      >{{ errors.first('Company Name') }}</span>
                    </div>
                  </div>
                </div>

                <div class="vx-row mb-6">
                  <div class="col-md-4 text-right align-self-center">
                    <h5 class="mb-0">Category</h5>
                  </div>
                  <div class="col-7">
                    <div>
                      <multiselect
                        disabled
                        v-model="company_value"
                        track-by="company_id"
                        label="company_name"
                        @input="({company_id})=> this.form.company_id = company_id"
                        :options="companies"
                        name="Company Name"
                        :searchable="false"
                        :deselectLabel="''"
                        :select-label="''"
                        :allow-empty="false"
                        deselect-label="Can't remove this value"
                      >
                        <template
                          slot="singleLabel"
                          slot-scope="{ option }"
                        >{{ option.company_name }}</template>
                      </multiselect>
                      <span
                        class="text-danger text-sm"
                        v-show="errors.has('Company Name')"
                      >{{ errors.first('Company Name') }}</span>
                    </div>
                  </div>
                </div>

                <div class="vx-row mb-6">
                  <div class="col-md-4 text-right align-self-center">
                    <h5 class="mb-0">Subcategory</h5>
                  </div>
                  <div class="col-7">
                    <div>
                      <multiselect
                        disabled
                        v-model="company_value"
                        track-by="company_id"
                        label="company_name"
                        @input="({company_id})=> this.form.company_id = company_id"
                        :options="companies"
                        name="Company Name"
                        :searchable="false"
                        :deselectLabel="''"
                        :select-label="''"
                        :allow-empty="false"
                        deselect-label="Can't remove this value"
                      >
                        <template
                          slot="singleLabel"
                          slot-scope="{ option }"
                        >{{ option.company_name }}</template>
                      </multiselect>
                      <span
                        class="text-danger text-sm"
                        v-show="errors.has('Company Name')"
                      >{{ errors.first('Company Name') }}</span>
                    </div>
                  </div>
                </div>

                <div class="vx-row mb-6">
                  <div class="col-md-4 text-right align-self-center">
                    <h5 class="mb-0">Budget Amount</h5>
                  </div>
                  <div class="col-7">
                    <div class>
                      <vs-input
                        v-validate="'required'"
                        name="department name"
                        v-model="form.department_name"
                        class="w-full"
                      />
                      <span
                        class="text-danger text-sm"
                        v-show="errors.has('department name')"
                      >{{ errors.first('department name') }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="vx-row">
              <div class="vx-col mx-auto">
                <vs-button type="filled" class="mr-3">Save</vs-button>
              </div>
            </div>
          </FocusLock>
        </form>
      </vs-popup>
    </div>-->
  </div>
</template>

<script>
import { BASEURL } from "@/config/index.js";
import axios from "axios";
import { TokenService } from "@/services/storage.service.js";
import projectService from "@/services/projectService.js";
import budgetList from "@/views/apps/budget/budgetList.vue";
import budgetReport from "@/views/apps/budget/budgetReport.vue";
import PermissionVariables from "@/services/UserManagementVaraibles.js";
// import BudgetService from "@/services/budgetService.js";
import { mapState, mapGetters, mapActions } from "vuex";

export default {
  data() {
    return {
      categoryValue: 0,
      categoryList: [],
      shoot_days: 0,
      setting_days: 0,
      permissionPageAccessArry: window.localStorage.getItem(
        "permissionPageAccess"
      )
        ? localStorage.getItem("permissionPageAccess").split(",")
        : "",
      PermissionVariables: PermissionVariables,
      loginUserRole: window.localStorage.getItem("UserRole"),
      excelurl: BASEURL(),
      popupActivo: false,
      isActive: false,
      analyticsData: "",
      isImp: false,
      show: false,
      items: [1, 2, 3, 4, 5, 6, 7, 8, 9],
      nextNum: 10,
      popupActive: false,
      companies: [],
      form: {
        project_id: null,
      },
      submitStatus: true,
      projects: [],
      projects_value: [],
      excelUpload: null,
      company_value: [],
      projectSelected: [],
      organization_id: "",
    };
  },
  computed: {
    projectId() {
      return this.$store.state.budgetObject.projectId;
    },
    tabIndex: {
      get() {
        return this.$store.state.tabIndex;
      },
      set(value) {
        this.$store.dispatch("tabIndex", value);
      },
    },
  },
  watch: {
    form: {
      handler: function (newVal, oldVal) {
        let len = Object.keys(this.form).length;
        if (
          len ===
          Object.keys(newVal).filter(
            (x, index) =>
              newVal[x] &&
              newVal[x] !== undefined &&
              String(newVal[x]).trim().length > 0
          ).length
        ) {
          this.submitStatus = false;
        } else {
          this.submitStatus = true;
        }
      },
      deep: true,
    },
  },
  beforeMount() {
    this.clearProjectID();
  },
  mounted() {
    this.organization_id = localStorage.getItem("OrganizationID");
    this.temp_token = localStorage.getItem("access_token");

    this.getProjectsList();
    eventBus.$on("refreshBudgetTable", (e) => {
      // this.$store.dispatch("tabIndex", 0);
      // this.$store.dispatch("tabIndex", 0);
      this.searchBudget();
    });
    eventBus.$on("refreshTableBudgetList", (e) => {
      this.searchBudget();
    });
    eventBus.$on("SetBudgetReportData", (e) => {
      // this.$store.dispatch("tabIndex", 0);
      this.$store.dispatch("tabIndex", 0);
    });
  },
  methods: {
    ...mapActions(["clearProjectID"]),
    // getBudgetReport: function () {
    //   eventBus.$emit("refreshTableBudgetReport");
    // },
    submitExcel: function () {
      this.$validator.validateAll().then((result) => {
        let that = this;
        that.$vs.loading();
        let formData = new FormData();
        formData.append("file", this.excelUpload);
        formData.append("project_id", this.projects_value.project_id);
        axios
          .post(
            that.excelurl +
              `/v2/budget/import/excel?organization_id=${that.organization_id}&setting_days=${this.setting_days}&shoot_days=${this.shoot_days}`,
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
                authorization: this.temp_token,
              },
            }
          )
          .then((response) => {
            const { data } = response;
            const input = this.$refs.uploadExcelFile;
            input.type = "text";
            input.type = "file";
            if (
              data &&
              data["error"] &&
              data.error["message"] &&
              data.error.message.length > 0
            ) {
              that.$vs.notify({
                title: "Error!",
                // title: "Duplicate entries have not been imported.",
                text: data.error.message || "Error",
                iconPack: "feather",
                time: 35000,
                icon: "check_box",
                color: "warning",
              });
            } else {
              that.$vs.notify({
                title: "Updated!",
                text: data.data.message || "Updated",
                iconPack: "feather",
                icon: "check_box",
                color: "success",
              });
              this.popupActivo = false;
            }

            that.$vs.loading.close();
            // that.$vs.notify({
            //   title: "Updated!",
            //   text: data.message,
            //   iconPack: "feather",
            //   icon: "check_box",
            //   color: "success"
            // });
            eventBus.$emit("refreshTabledepa", data);
          })
          .catch((error) => {
            that.$vs.loading.close();
            this.popupActivo = false;
            const input = this.$refs.uploadExcelFile;
            input.type = "text";
            input.type = "file";
            that.$vs.notify({
              title: "Error!",
              text: error.message,
              iconPack: "feather",
              icon: "check_box",
              color: "warning",
            });
          });
      });
    },
    onClickSampleDownload: function (URL) {
      window.open(URL, "_blank");
    },
    uploadExcel: function (event) {
      this.excelUpload = event.target.files[0];
      this.$vs.notify({
        title: "Your file is imported",
        text: "Kindly click on the SUBMIT button to upload data succesfully",
        time: 5000,
        iconPack: "feather",
        icon: "check_box",
        color: "warning",
      });
    },

    searchBudget: async function () {
      let isProjectSelect = Object.keys(this.projectSelected).length;
      if (isProjectSelect > 0) {
        await this.$store.dispatch("getBudgetList", {
          project_id: this.projectSelected.project_id,
          // page: currentPage
        });
        setTimeout(async () => {
          await eventBus.$emit("refreshTableBudgetReport");
        }, 2);

        // setTimeout(async () => {
        //   alert("3");
        //   await eventBus.$emit("checkBudget");
        // }, 2);
      } else {
        this.$vs.notify({
          title: "Select Project",
          iconPack: "feather",
          icon: "check_box",
          color: "warning",
        });
      }
    },
    myFilter: function () {
      this.isActive = !this.isActive;
    },
    showModal() {
      this.popupActive = true;
      // this.getCompanyList();
    },
    //  getAllProjects
    getProjectsList: function () {
      this.projects = [];
      projectService
        .getAllProjects()
        .then((response) => {
          const { data } = response;
          if (data.Status) {
            //  &&
            //               !this.permissionPageAccessArry.includes(
            //                 PermissionVariables.ViewOrganizationProjects
            //               )
            if (
              !this.permissionPageAccessArry.includes(
                PermissionVariables.ViewOrganizationProjects
              ) &&
              this.loginUserRole != "Admin"
            ) {
              data.data.map((x) => {
                if (
                  this.permissionPageAccessArry.includes(
                    "15P" + x.project_id
                  ) ||
                  this.permissionPageAccessArry.includes(
                    "16P" + x.project_id
                  ) ||
                  this.permissionPageAccessArry.includes(
                    "17P" + x.project_id
                  ) ||
                  this.permissionPageAccessArry.includes(
                    "18P" + x.project_id
                  ) ||
                  this.permissionPageAccessArry.includes("19P" + x.project_id)
                ) {
                  this.projects.push(x);
                }
              });
            } else {
              this.projects = data.data;
            }
            if (
              this.$store.state.budgetObject.projectId != null &&
              this.$store.state.budgetObject.projectId != undefined &&
              this.$store.state.budgetObject.projectId != ""
            ) {
              this.projectSelected = data.data.filter((x) => {
                return x.project_id == this.$store.state.budgetObject.projectId;
              });
            }
          } else {
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  components: {
    budgetList,
    budgetReport,
  },
};
</script>

<style lang="scss">
#dashboard-analytics {
  .greet-user {
    position: relative;
    .decore-left {
      position: absolute;
      left: 0;
      top: 0;
    }
    .decore-right {
      position: absolute;
      right: 0;
      top: 0;
    }
  }

  @media (max-width: 576px) {
    .decore-left,
    .decore-right {
      width: 140px;
    }
  }
}
</style>
