<template>
  <div>
    <div @click="getPreview()">{{ params.data[params.colDef.field] }}</div>

    <div class="demo-alignment">
      <vs-popup
        fullscreen
        :id="'popmodalView' + params.data.budget_id"
        class="holamundo p-2"
        title="Expense Detail"
        :active.sync="popupActive"
      >
        <div class="row mx-0 text-center mt-5 mb-base">
          <div class="col-3">
            <h6>Budget Code:</h6>
            <h3 class="text-primary">{{ params.data.budget_code }}</h3>
          </div>

          <!-- <div class="col-1">
            <h6>Project:</h6>
            <h3 class="text-primary">{{ expenseDetail.expense_code }}</h3>
          </div> -->
          <!-- <div class="col-1">
            <h6>Category:</h6>
            <h3 class="text-primary">{{ params.data.category_name }}</h3>
          </div> -->
          <!-- <div class="col-2">
            <h6>Subcategory:</h6>
            <h3 class="text-primary">{{ params.data.sub_category_name }}</h3>
          </div> -->

          <div class="col-3">
            <h6>Actual Expense Price:</h6>
            <h3 class="text-primary">{{ params.data.actual_amount }}</h3>
          </div>
        </div>

        <div>
          <!-- <h4 class>DPR List</h4> -->

          <div class="col-12 row">
            <div class="col-10"></div>
            <div class="col-2">
              <vs-input
                class="mb-4 md:mb-0 mr-4 ml-3"
                v-model="searchQuery"
                @input="updateSearchQuery"
                placeholder="Search..."
              />
            </div>
          </div>

          <!-- <vs-button type="filled" class="mr-3">Save</vs-button> -->

          <ag-grid-vue
            :gridOptions="gridOptions"
            class="ag-theme-material w-100 my-4 ag-grid-table"
            :columnDefs="columnDefs"
            :defaultColDef="defaultColDef"
            :rowClicked="onRowDataChanged"
            :rowData="budgetLogs"
            rowSelection="multiple"
            colResizeDefault="shift"
            :animateRows="true"
            :floatingFilter="false"
          ></ag-grid-vue>
          <!-- :paginationPageSize="paginationPageSize"
            :pagination="true"
          :suppressPaginationPanel="true"-->
          <!-- <vs-pagination :total="totalPages" :max="maxPageNumbers" v-model="currentPage" /> -->
        </div>
      </vs-popup>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { AgGridVue } from "ag-grid-vue";
import contacts from "../../ui-elements/ag-grid-table/data.json";
import DepartmentService from "@/services/departmentService";
import BudgetService from "@/services/budgetService.js";
import "@/assets/scss/vuesax/extraComponents/agGridStyleOverride.scss";
import moment from "moment";
import imagePopUp from "../projects/imagePopUp.vue";

// var moment = require("moment");
// moment().format();
export default Vue.extend({
  components: {
    AgGridVue,
  },
  data() {
    return {
      params: null,
      expenseDetail: [],
      expenseList: [],
      totalPages: 0,
      popupActive: false,
      gridApi: null,
      searchQuery: "",

      gridOptions: {},
      maxPageNumbers: 7,
      budgetLogs: [],
      defaultColDef: {
        sortable: true,
        editable: false,
        resizable: true,
        suppressMenu: true,
      },
      columnDefs: [],
      // project_id: ""
    };
  },

  beforeMount: function () {
    //console.log(this.$store.state.budgetObject.projectId, "fsdfdsfsd");

    this.budget_id = 0;
    this.expenseDetail = this.params.data;
    this.columnDefs = [
      {
        headerName: "Project",
        field: "prefixCode",
        filter: false,
        width: 150,
        suppressSizeToFit: true,
      },
      {
        headerName: "User",
        field: "user_name",
        filter: false,
        width: 150,
        suppressSizeToFit: true,
      },
      {
        headerName: "Category",
        field: "category_name",
        filter: false,
        width: 150,
        suppressSizeToFit: true,
      },
      {
        headerName: "Sub Category",
        field: "sub_category_name",
        filter: false,
        width: 150,
        suppressSizeToFit: true,
      },
      {
        headerName: "Expense Name",
        field: "po_name",
        filter: false,
        width: 200,
      },
      // {
      //   headerName: "Subcategory",
      //   field: "amount",
      //   filter: false,
      //   width: 200
      // },
      {
        headerName: " Amount",
        field: "total",
        filter: false,
        width: 150,
      },

      {
        headerName: "Description",
        field: "purchase_description",
        filter: false,
        width: 300,
      },
      {
        headerName: "Status",
        field: "purchase_status",
        filter: false,
        sortable: false,
        width: 150,
        cellRenderer: function (params) {
          if (params.value == 0) {
            return `<div class="text-warning font-bold">Pending<div>`;
          } else if (params.value == 1) {
            return `<div class="text-success font-bold">Approved<div>`;
          } else if (params.value == 2) {
            return `<div class="text-danger font-bold">Rejected<div>`;
          }
        },
      },
      {
        headerName: "Multiple Images",
        field: "purchase_image",
        filter: true,
        width: 200,
        sortable: false,
        cellRendererFramework: imagePopUp,
      },

      // cellRendererFramework: viewBudget
    ];
  },
  mounted() {
    eventBus.$on(
      "showPopupExpense" +
        this.params.data.budget_code +
        this.params.colDef.headerName,
      (e) => {
        if (this.params.data.budget_code == e.budget_code) {
          console.log(e, "dsfsdf");
          this.popupActive = e.show;
        }
      }
    );

    this.gridApi = this.gridOptions.api;
  },
  beforeDestroy() {
    let _id = document.getElementById(
      "popmodalView" + this.params.data.budget_code
    );
    // _id.setAttribute("style", "display: none;");
  },
  watch: {
    "$store.state.windowWidth"(val) {
      if (val <= 576) {
        this.maxPageNumbers = 4;
        this.gridOptions.columnApi.setColumnPinned("email", null);
      } else this.gridOptions.columnApi.setColumnPinned("email", "left");
    },
  },
  computed: {
    paginationPageSize() {
      if (this.gridApi) return this.gridApi.paginationGetPageSize();
      else return 100;
    },
    currentPage: {
      get() {
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1;
        else return 1;
      },
      set(val) {
        if (val) {
          this.getDepartmentList(val);
          return val;
        } else return 1;
      },
    },
  },
  methods: {
    onRowDataChanged(e) {
      console.log("e :", e);
    },
    onChangeStatus: function () {
      console.log("object");
    },
    updateSearchQuery(val) {
      this.gridApi.setQuickFilter(val);
    },
    onFromChange(selectedDates, dateStr, instance) {
      this.$set(this.configTodateTimePicker, "minDate", dateStr);
    },
    onToChange(selectedDates, dateStr, instance) {
      this.$set(this.configFromdateTimePicker, "maxDate", dateStr);
    },
    getPreview: function () {
      this.popupActive = true;
      this.getBudgetLogList();

      // this.gridApi.sizeColumnsToFit();
      // BudgetService.getSingleBudget(this.budget_id)
      //   .then(response => {
      //     const { data } = response;
      //     if (data.Status) {
      //       this.expenseDetail = data.data;

      //     } else {
      //     }
      //   })
      //   .catch(error => {
      //     console.log("error :", error);
      //   });
    },
    getBudgetLogList: function () {
      BudgetService.getExpenseBudgetLog(
        this.params.data.parent_id,
        this.$store.state.budgetObject.projectId
      )
        .then((response) => {
          const { data } = response;
          if (data.Status) {
            console.log(data.data);
            this.budgetLogs = data.data;
            // console.log(
            //   "this.budgetLogsthis.budgetLogsthis.budgetLogsthis.budgetLogs :",
            //   this.budgetLogs
            // );
          } else {
            console.log("error :", error);
          }
        })
        .catch((error) => {});
    },
  },
});
</script>

<style></style>
