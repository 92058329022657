<!-- =========================================================================================
    File Name: budgetReport.vue
    Description:
    ----------------------------------------------------------------------------------------
    Item Name:
    Author:
    Author URL:
========================================================================================== -->

<template>
  <div>
    <!-- BUDGET -->
    <div id="ag-grid-demo">
      <!-- TABLE ACTION ROW -->
      <!-- <vx-card>
        <div class="flex flex-wrap justify-between items-center form-title">
          <h4 class>Filter Budget Report</h4>
          <div class="flex flex-wrap justify-end items-center">
            <div class="flex flex-wrap items-center justify-between ag-grid-table-actions-right">
              <vs-input
                class="mb-4 md:mb-0 mr-4"
                v-model="searchQuery"
                @input="updateSearchQuery"
                placeholder="Search..."
              />
            </div>
          </div>
        </div>
        <div>
          <div class="row">
            <div class="col-6">
              <div class="d-flex align-items-center">
                <div class="w-100 mr-2">
                  <flat-pickr
                    :config="configFromdateTimePicker"
                    v-model="fromDate"
                    placeholder="From Date"
                    @on-change="onFromChange"
                  />
                </div>

                <div class="w-100 ml-2">
                  <flat-pickr
                    :config="configTodateTimePicker"
                    v-model="toDate"
                    placeholder="To Date"
                    @on-change="onToChange"
                  />
                </div>

                <div class="w-100 ml-4">
                  <vs-button color="primary" class="hover-btn" type="filled">Submit</vs-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </vx-card>-->

      <div class="row">
        <div class="col-12">
          <vx-card class="form-title mb-base" title="Budget Report List">
            <ag-grid-vue
              :gridOptions="gridOptions"
              class="ag-theme-material w-100 my-4 ag-grid-table"
              :columnDefs="columnDefs"
              :defaultColDef="defaultColDef"
              :rowClicked="onRowDataChanged"
              :rowData="budgetReport"
              rowSelection="multiple"
              colResizeDefault="shift"
              :animateRows="true"
              :floatingFilter="false"
              :pinnedBottomRowData="pinnedBottomRowData"
            ></ag-grid-vue>
            <!-- <vs-pagination
              :total="totalPages"
              :max="maxPageNumbers"
              v-model="currentPage"
            /> -->
          </vx-card>
        </div>

        <div class="col-6">
          <vx-card class="form-title mb-base" title="Budget Report Chart">
            <div id="chart">
              <apexchart
                type="bar"
                height="350"
                :options="chartOptions"
                :series="series"
              ></apexchart>
            </div>
          </vx-card>
          <!-- <button @click="updateChart">Update!</button> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { AgGridVue } from "ag-grid-vue";
import { BASEURL } from "@/config/index.js";
import contacts from "../../ui-elements/ag-grid-table/data.json";
import DatetimePickerStartEnd from "@/views/components/extra-components/datetime-picker/DatetimePickerStartEnd.vue";
import VueApexCharts from "vue-apexcharts";
import flatPickr from "vue-flatpickr-component";
import store from "@/store/store";
import BudgetService from "@/services/budgetService.js";
import viewAllExpenseDetails from "./viewAllExpenseDetails.vue";
import vue from "vue";
import "flatpickr/dist/flatpickr.css";
import "@/assets/scss/vuesax/extraComponents/agGridStyleOverride.scss";

export default {
  components: {
    AgGridVue,
    flatPickr,
    VueApexCharts,
    apexchart: VueApexCharts,
    DatetimePickerStartEnd,
  },
  props: ["listType"],
  data() {
    return {
      pinnedBottomRowData: null,
      excelurl: BASEURL(),
      totalPages: 0,
      overlayLoadingTemplate: null,
      archive: this.listType,
      departments: [],
      User: "Select User",
      switchValue: true,
      searchQuery: "",
      budgetReport: [],
      gridOptions: {
        onRowClicked: function (event) {
          store.dispatch("selectedCode", event.data.budget_code);
          eventBus.$emit("SetBudgetReportData", event.data.budget_code);
        },
      },
      maxPageNumbers: 7,
      gridApi: null,
      defaultColDef: {
        sortable: true,
        editable: false,
        resizable: true,
        suppressMenu: true,
      },
      fromDate: null,
      cityOptions: [
        { text: "New York", value: "new-york" },
        { text: "Chicago", value: "chicago" },
        { text: "San Francisco", value: "san-francisco" },
        { text: "Boston", value: "boston" },
      ],
      contacts: contacts,
      columnDefs: [
        // {
        //   headerName: "Code",
        //   field: "budget_code",
        //   filter: false,
        //   width: 100,
        //   suppressSizeToFit: true,
        //   // ColumnEvent: {},
        //   pinnedRowCellRendererParams: { style: { "font-style": "bold" } },
        //   cellRendererFramework: viewAllExpenseDetails,
        // },
        {
          headerName: "Category",
          field: "category_name",
          filter: false,
          width: 120,
          cellRendererFramework: viewAllExpenseDetails,
        },
        {
          headerName: "Initial Budget Amount",
          field: "formattedTotalInitialBudgetAmount",
          filter: false,
          width: 120,
          cellRendererFramework: viewAllExpenseDetails,
        },
        {
          headerName: "Revised Budget Amount",
          field: "budget_amount",
          filter: false,
          width: 120,
          cellRendererFramework: viewAllExpenseDetails,
        },
        {
          headerName: "Variance Budget Amount",
          field: "formattedTotalVarianceAmount",
          filter: false,
          width: 120,
          cellRendererFramework: viewAllExpenseDetails,
          cellStyle: (params) => {
            if (params.data.totalVarianceAmount > 0) {
              //mark police cells as red
              return { backgroundColor: "green" };
            } else if (params.data.totalVarianceAmount < 0) {
              return { backgroundColor: "red" };
            }
            return null;
          },
        },
        {
          headerName: "Contractual Unpaid",
          field: "contractAmount",
          filter: false,
          width: 250,
        },
        {
          headerName: "Daily Cost Report",
          field: "projectAmount",
          filter: false,
          width: 250,
        },
        {
          headerName: "Actual",
          field: "actual_amount",
          filter: false,
          width: 120,
          cellRendererFramework: viewAllExpenseDetails,
        },
        {
          headerName: "Credit Amount",
          field: "credit_amount",
          filter: false,
          width: 120,
          cellRenderer: function (params) {
            return `<div class="" style="color:red">${params.data.credit_amount} <div>`;
          },
          // cellRendererFramework: viewAllExpenseDetails
        },
        {
          headerName: "Balance",
          field: "balance_amount",
          filter: false,
          width: 120,
          cellRendererFramework: viewAllExpenseDetails,
        },
      ],
      toDate: null,
      configFromdateTimePicker: {
        minDate: new Date(),
        maxDate: null,
      },
      configTodateTimePicker: {
        minDate: null,
      },
      selected: [],
      log: [],
      series: [
        {
          name: "Actual Amount",
          data: [],
        },
        {
          name: "Budget Amount",
          data: [],
        },
      ],
      chartOptions: {
        chart: {
          type: "bar",
          height: 350,
          stacked: true,
          toolbar: {
            show: true,
          },
          zoom: {
            enabled: false,
          },
          // selection: {
          //   enabled: true,
          // },
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              legend: {
                position: "top",
                offsetY: 0,
              },
            },
          },
        ],
        plotOptions: {
          bar: {
            horizontal: false,
          },
        },
        xaxis: {
          type: "text",
          categories: [],
        },
        legend: {
          position: "top",
          offsetY: 10,
        },
        fill: {
          opacity: 1,
        },
      },
    };
  },
  beforeMount() {
    if (localStorage.getItem("OrganizationID") != 35) {
      this.columnDefs = [
        {
          headerName: "Category",
          field: "category_name",
          filter: false,
          width: 120,
          cellRendererFramework: viewAllExpenseDetails,
        },
        {
          headerName: "Budget Amount",
          field: "budget_amount",
          filter: false,
          width: 120,
          cellRendererFramework: viewAllExpenseDetails,
        },
        {
          headerName: "Contractual Unpaid",
          field: "contractAmount",
          filter: false,
          width: 250,
        },
        {
          headerName: "Daily Cost Report",
          field: "projectAmount",
          filter: false,
          width: 250,
        },
        {
          headerName: "Actual",
          field: "actual_amount",
          filter: false,
          width: 120,
          cellRendererFramework: viewAllExpenseDetails,
        },
        {
          headerName: "Credit Amount",
          field: "credit_amount",
          filter: false,
          width: 120,
          cellRenderer: function (params) {
            return `<div class="" style="color:red">${params.data.credit_amount} <div>`;
          },
          // cellRendererFramework: viewAllExpenseDetails
        },
        {
          headerName: "Balance",
          field: "balance_amount",
          filter: false,
          width: 120,
          cellRendererFramework: viewAllExpenseDetails,
        },
      ];
    } else {
      this.columnDefs = [
        // {
        //   headerName: "Code",
        //   field: "budget_code",
        //   filter: false,
        //   width: 100,
        //   suppressSizeToFit: true,
        //   // ColumnEvent: {},
        //   pinnedRowCellRendererParams: { style: { "font-style": "bold" } },
        //   cellRendererFramework: viewAllExpenseDetails,
        // },
        {
          headerName: "Category",
          field: "category_name",
          filter: false,
          width: 120,
          cellRendererFramework: viewAllExpenseDetails,
        },
        {
          headerName: "Initial Budget Amount",
          field: "formattedTotalInitialBudgetAmount",
          filter: false,
          width: 120,
          cellRendererFramework: viewAllExpenseDetails,
        },
        {
          headerName: "Revised Budget Amount",
          field: "budget_amount",
          filter: false,
          width: 120,
          cellRendererFramework: viewAllExpenseDetails,
        },
        {
          headerName: "Variance Budget Amount",
          field: "formattedTotalVarianceAmount",
          filter: false,
          width: 120,
          cellRendererFramework: viewAllExpenseDetails,
          cellStyle: (params) => {
            if (params.data.totalVarianceAmount > 0) {
              //mark police cells as red
              return { backgroundColor: "green" };
            } else if (params.data.totalVarianceAmount < 0) {
              return { backgroundColor: "red" };
            }
            return null;
          },
        },
        {
          headerName: "Contractual Unpaid",
          field: "contractAmount",
          filter: false,
          width: 250,
        },
        {
          headerName: "Daily Cost Report",
          field: "projectAmount",
          filter: false,
          width: 250,
        },
        {
          headerName: "Actual",
          field: "actual_amount",
          filter: false,
          width: 120,
          cellRendererFramework: viewAllExpenseDetails,
        },
        {
          headerName: "Credit Amount",
          field: "credit_amount",
          filter: false,
          width: 120,
          cellRenderer: function (params) {
            return `<div class="" style="color:red">${params.data.credit_amount} <div>`;
          },
          // cellRendererFramework: viewAllExpenseDetails
        },
        {
          headerName: "Balance",
          field: "balance_amount",
          filter: false,
          width: 120,
          cellRendererFramework: viewAllExpenseDetails,
        },
      ];
    }
    this.gridOptions = {
      getRowStyle(params) {
        let temp_str = params.data.actual_amount;
        if (temp_str.length > 3) {
          temp_str = params.data.actual_amount.replace(/\,/g, "");
        }

        let temp_str1 = params.data.budget_amount;
        if (temp_str1.length > 3) {
          temp_str1 = params.data.budget_amount.replace(/\,/g, "");
        }
        if (parseInt(temp_str1) < parseInt(temp_str)) {
          return { background: "red" };
        } else {
          return null;
        }
      },
    };

    if (this.projectId) {
      this.getBudgetReportList();
    }
    eventBus.$on("refreshTableBudgetReport", (e) => {
      this.getBudgetReportList();
    });
    // this.setColums();
  },
  watch: {
    "$store.state.windowWidth"(val) {
      if (val <= 576) {
        this.maxPageNumbers = 4;
        this.gridOptions.columnApi.setColumnPinned("email", null);
      } else this.gridOptions.columnApi.setColumnPinned("email", "left");
    },
  },
  computed: {
    projectId() {
      return this.$store.state.budgetObject.projectId;
    },
    paginationPageSize() {
      if (this.gridApi) return this.gridApi.paginationGetPageSize();
      else return 100;
    },
    // totalPages() {
    //   if (this.gridApi) return this.gridApi.paginationGetTotalPages();
    //   else return 0;
    // },
    currentPage: {
      get() {
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1;
        else return 1;
      },
      set(val) {
        // if (val) {
        //   this.getDepartmentList(val);
        //   return val;
        // } else return 1;
      },
    },
  },
  methods: {
    cellClicked: function () {
      console.log("object :");
    },
    // New Added
    handleSearch(searching) {
      console.log(`The user searched for: ${searching}`);
    },
    handleChangePage(page) {
      console.log(`The user changed the page to: ${page}`);
    },
    handleSort(key, active) {
      console.log(`the user ordered: ${key} ${active}`);
    },

    // New Added

    onRowDataChanged(e) {
      console.log("e :", e);
    },
    onChangeStatus: function () {
      console.log("object");
    },
    updateSearchQuery(val) {
      this.gridApi.setQuickFilter(val);
    },
    onFromChange(selectedDates, dateStr, instance) {
      this.$set(this.configTodateTimePicker, "minDate", dateStr);
    },
    onToChange(selectedDates, dateStr, instance) {
      this.$set(this.configFromdateTimePicker, "maxDate", dateStr);
    },
    getBudgetReportList: function () {
      BudgetService.getBudgetReportsList(
        this.projectId || this.$store.state.budgetObject.projectId
      )
        .then((response) => {
          const { data } = response;
          if (data.Status) {
            this.budgetReport = data.data;
            console.log(this.budgetReport, "dsfdsf");

            this.categories = this.budgetReport.map((x) => {
              return x.budget_code;
            });

            let actual_amount_data = this.budgetReport.map((x) => {
              return x.actual_amount_new;
            });

            let budget_amount_data = this.budgetReport.map((x) => {
              return x.budget_amount_new;
            });

            this.series = this.series.map((x) => {
              if (x.name == "Actual Amount") {
                x.data = actual_amount_data;
              } else if (x.name == "Budget Amount") {
                x.data = budget_amount_data;
              }
              return x;
            });

            this.gridApi.sizeColumnsToFit();
            let obj = {
              budget_code: "Total",
            }; // SHOW TOTAL AMOUNT IN TABLE
            // ["budget_amount", "actual_amount", "balance_amount"].map(x => {
            let budget_amount_new = -0;
            let actual_amount_new = -0;
            let balance_amount_new = 0;
            let contractAmountNew = 0;
            let projectAmount_new = 0;
            let credit_amount_new = 0;
            console.log("fdsfds");
            data.data.map((x) => {
              budget_amount_new =
                budget_amount_new + parseInt(x.budget_amount_new);
              actual_amount_new =
                actual_amount_new + parseInt(x.actual_amount_new);
              balance_amount_new =
                balance_amount_new + parseInt(x.balance_amount_new);
              contractAmountNew =
                contractAmountNew + parseInt(x.contractAmount_new);
              projectAmount_new =
                projectAmount_new + parseInt(x.projectAmount_new);
              credit_amount_new =
                credit_amount_new + parseInt(x.credit_amount_new);
            });
            obj["balance_amount"] = balance_amount_new;
            obj["actual_amount"] = actual_amount_new;
            obj["budget_amount"] = budget_amount_new;
            obj["projectAmount"] = projectAmount_new;
            obj["contractAmount"] = contractAmountNew;
            obj["credit_amount"] = credit_amount_new;
            console.log(obj);
            // });
            // console.log(obj);
            this.pinnedBottomRowData = [obj];
            this.updateChart();
          } else {
          }
        })
        .catch((error) => {
          console.log("error :", error);
        });
    },
    updateChart() {
      this.chartOptions = {
        ...this.chartOptions,
        ...{
          xaxis: {
            categories: this.categories,
          },
        },
      };
    },
    // getDepartmentList: function(currentPage) {
    //   let filter = {
    //     page: currentPage
    //   };
    //   if (this.archive == "archive") {
    //     filter["archive"] = true;
    //   }

    //   departmentService
    //     .getAllDepartments(filter)
    //     .then(response => {
    //       const { data } = response;
    //       if (data.err || data.Status == false) {
    //       } else {
    //         this.departments = data.data;
    //         this.totalPages = data.pagination.last_page;
    //         this.gridApi.sizeColumnsToFit();
    //       }
    //     })
    //     .catch(error => {
    //       console.log(error);
    //     });
    // }
  },
  mounted() {
    // if (this.checkPermission(["department", "GET"])) {
    //   this.getDepartmentList(this.currentPage);
    // }
    this.gridApi = this.gridOptions.api;
  },
};
</script>
