<template>
  <div v-if="checkPermission(['budget', 'PUT'])">
    <span class="text-center table-edit-icon" @click="showModal">
      <i class="text-center material-icons">edit</i>
    </span>
    <div class="centerx">
      <vs-popup
        :id="'popmodal' + params.data.budget_id"
        class="holamundo editModal"
        title="Update Budget Record"
        :active.sync="popupActive"
      >
        <form>
          <FocusLock>
            <div class="vx-row">
              <!-- LEFT SIDE -->
              <div class="col-12">
                <div class="vx-row mb-6">
                  <div class="col-md-4 text-right align-self-center">
                    <h5 class="mb-0">Budget Code</h5>
                  </div>
                  <div class="col-7">
                    <!-- Start ************* -->
                    <div>
                      <multiselect
                        disabled
                        v-model="form.budgetCode_value"
                        :options="budgetCodes"
                        :searchable="false"
                        :show-labels="false"
                        placeholder="Budget Code"
                        open-direction="bottom"
                      ></multiselect>
                    </div>
                    <!-- ************* End -->
                  </div>
                </div>

                <div class="vx-row mb-6">
                  <div class="col-md-4 text-right align-self-center">
                    <h5 class="mb-0">Category</h5>
                  </div>
                  <div class="col-7">
                    <!-- Start ************* -->
                    <div>
                      <vue-simple-suggest
                        disabled
                        v-model="form.selectedCategory"
                        :list="categoryTemp"
                        display-attribute="category_name"
                        value-attribute="category_id"
                        :filter-by-query="true"
                        @select="onSuggestSelect"
                      ></vue-simple-suggest>
                      <span
                        class="text-danger text-sm"
                        v-show="errors.has('Category Name')"
                      >{{ errors.first("Category Name") }}</span>
                    </div>
                    <!-- ************* End -->
                  </div>
                </div>

                <div class="vx-row mb-6">
                  <div class="col-md-4 text-right align-self-center">
                    <h5 class="mb-0">Subcategory</h5>
                  </div>
                  <div class="col-7">
                    <!-- Start ************* -->
                    <div>
                      <vue-simple-suggest
                        disabled
                        v-model="form.selectedSubCategory"
                        :list="SubcategoryTemp"
                        display-attribute="category_name"
                        value-attribute="category_id"
                        :filter-by-query="true"
                      ></vue-simple-suggest>
                      <span
                        class="text-danger text-sm"
                        v-show="errors.has('Subcategory Name')"
                      >{{ errors.first("Subcategory Name") }}</span>
                    </div>
                    <!-- ************* End -->
                  </div>
                </div>

                <div class="vx-row mb-6">
                  <div class="col-md-4 text-right align-self-center">
                    <h5 class="mb-0">Amount</h5>
                  </div>
                  <div class="col-7">
                    <div class>
                      <!-- v-validate="'required|regex:^[0-9]+'" -->
                      <vs-input
                        v-validate="'required'"
                        type="number"
                        name="Budget Amount"
                        v-model.number="form.budgetAmount"
                        class="w-full"
                      />
                      <span
                        class="text-danger text-sm"
                        v-show="errors.has('Budget Amount')"
                      >{{ errors.first("Budget Amount") }}</span>
                    </div>
                  </div>
                </div>

                <div class="vx-row mb-5">
                  <div class="col-md-4 text-right align-self-center">
                    <h5 class="mb-0">Reason</h5>
                  </div>
                  <div class="col-7">
                    <div class>
                      <vs-textarea v-model="changeReason" class="mb-0" rows="4" />
                      <span
                        class="text-danger text-sm"
                        v-show="errors.has('Change Reason')"
                      >{{ errors.first("Change Reason") }}</span>
                    </div>
                  </div>
                </div>
                <!-- <div class="vx-row mb-6">
                  <div class="col-md-4 text-right align-self-center">
                    <h5 class="mb-0">Status</h5>
                  </div>
                  <div class="col-7 h_30">
                    <div class>
                      <b-form-checkbox
                        value="1"
                        unchecked-value="2"
                        v-model="form.budget_active"
                        name="check-button"
                        switch
                      ></b-form-checkbox>
                    </div>
                  </div>
                </div>-->
              </div>
            </div>

            <!-- SUBMIT BUTTON -->
            <div class="vx-row">
              <div class="vx-col mx-auto">
                <!-- :disabled="submitStatus" -->
                <!-- @click.prevent="submitForm" -->
                <vs-button type="filled" class @click.prevent="submitForm">Update</vs-button>
                <!-- <vs-button color="warning" type="border" class @click.prevent="clearForm">Reset</vs-button> -->
              </div>
            </div>
          </FocusLock>
        </form>
      </vs-popup>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import departmentService from "@/services/departmentService.js";
import CompanyService from "@/services/companyService.js";
import BudgetService from "@/services/budgetService.js";
import VueSimpleSuggest from "vue-simple-suggest";
import VueSuggest from "vue-simple-suggest/lib";
export default Vue.extend({
  components: {
    VueSuggest,
    VueSimpleSuggest,
  },
  data() {
    return {
      switch1: true,
      submitStatus: true,
      categories: [],
      subCategories: [],
      categoryTemp: [],
      SubcategoryTemp: [],
      project_id: null,
      form: {
        budgetCode_value: "",
        selectedCategory: "",
        selectedSubCategory: "",
        budgetAmount: "",
      },
      changeReason: "",
      budgetCodes: [
        "Select option",
        "options",
        "selected",
        "mulitple",
        "label",
      ],
      companies: [],
      company_value: [],
      department_value: [],
      popupActive: false,
      params: null,
      value: null,
      count: 0,
    };
  },
  watch: {
    form: {
      handler: function (newVal, oldVal) {
        if (this.count > 0) {
          this.submitStatus = false;
        }
        this.count++;
      },
      deep: true,
    },
  },
  computed: {
    projectId() {
      return this.$store.state.budgetObject.projectId;
    },
  },
  beforeMount() {
    this.form.budgetCode_value = this.params.data.budget_code;
    this.form.selectedCategory = this.params.data.category_name;
    this.form.selectedSubCategory = this.params.data.sub_category_name;
    this.form.budgetAmount = this.params.data.budget_amount;
    this.budget_id = this.params.value;
  },
  beforeDestroy() {
    let _id = document.getElementById("popmodal" + this.params.data.budget_id);
    _id.setAttribute("style", "display: none;");
  },
  methods: {
    onSuggestSelect: function (selected) {
      this.getSubcategoryList(selected.category_id || null);
    },
    showModal() {
      this.popupActive = true;
    },
    submitForm() {
      this.$validator.validateAll().then((result) => {
        if (this.changeReason.length > 0) {
          this.$vs.loading();
          if (result) {
            let id = this.params.data.budget_id;
            let payload = {
              budget_code: this.form.budgetCode_value,
              category_name: this.form.selectedCategory,
              sub_category_name: this.form.selectedSubCategory,
              budget_amount: this.form.budgetAmount,
              project_id: this.projectId,
              reason: this.changeReason,
            };
            BudgetService.editBudget(payload, id)
              .then((response) => {
                this.$vs.loading.close();
                const { data } = response;
                this.popupActive = false;
                if (data.Status == true) {
                  this.$vs.notify({
                    time: 5000,
                    title: "Updated!",
                    text: data.Message,
                    iconPack: "feather",
                    icon: "check_box",
                    color: "success",
                  });
                  // setTimeout(() => {
                  //   eventBus.$emit("refreshBudgetTable", data);
                  // }, 1);
                  // this.$forceupdate();
                  this.$store.dispatch("getBudgetList", {
                    project_id: this.projectId,
                  });

                  this.clearForm();
                } else {
                  this.$vs.loading.close();
                  this.$vs.notify({
                    time: 5000,
                    title: "Error!",
                    text: data.Message,
                    iconPack: "feather",
                    icon: "check_box",
                    color: "warning",
                  });
                }
              })
              .catch((error) => {
                this.$vs.loading.close();
              });
          } else {
            // form have errors
          }
        } else {
          this.$vs.notify({
            time: 5000,
            title: "Reason Required",
            // text: data.Message,
            iconPack: "feather",
            icon: "check_box",
            color: "warning",
          });
        }
      });
    },

    clearForm: function () {
      Object.keys(this.form).map((x, index) => {
        /_active/.test(x) ? (this.form[x] = "1") : (this.form[x] = "");
      });
      this.$validator.reset();
    },
  },

  mounted() {
    // this.getCompanyList();
  },
});

// import Vue from "vue";

// export default Vue.extend({
//   template: ` {{params.value}}
//          <label class="switch">
//           <input @click="onChangeCheckbox" type="checkbox">
//           <span class="slider round"></span>
//     </label> `,
//   data: function () {
//     return {};
//   },
//   beforeMount() {},
//   mounted() {},
//   methods: {

//   }
// });
</script>
<style>
pre.selected {
  margin: 8px 0;
  height: 295px;
  overflow-x: scroll;
  overflow-y: scroll;
  border: 1px solid #cde;
  border-radius: 4px;
}
.v-model-event {
  background-color: white;
  border: 1px solid #cde;
  border-radius: 4px;
}
.v-model-event.selected {
  color: red;
}
.v-model-event:hover {
  border: 1px solid #2874d5;
  background-color: #2874d5;
  color: white;
}
.vue-simple-suggest .suggest-item .text {
  display: inline-block;
  line-height: 1;
  vertical-align: text-bottom;
  overflow: hidden;
  max-width: 72%;
  text-overflow: ellipsis;
}
.vue-simple-suggest .suggest-item .text span {
  white-space: nowrap;
}
.vue-simple-suggest .suggest-item button {
  float: right;
  line-height: 1;
  margin-left: 4px;
}
.vue-simple-suggest-enter-active.suggestions,
.vue-simple-suggest-leave-active.suggestions {
  transition: opacity 0.2s;
}
.vue-simple-suggest-enter.suggestions,
.vue-simple-suggest-leave-to.suggestions {
  opacity: 0 !important;
}
</style>
