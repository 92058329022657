<!-- =========================================================================================
    File Name: AgGridTable.vue
    Description: Ag Grid table
    ----------------------------------------------------------------------------------------
    Item Name: Vuesax Admin - VueJS Dashboard Admin Template
    Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div>
    <div></div>

    <!-- DEPARTMENTS LIST -->
    <div id="ag-grid-demo">
      <vx-card>
        <!-- TABLE ACTION ROW -->
        <div class="flex flex-wrap justify-between items-center form-title">
          <h4 class>Budget List</h4>
          <div class="flex flex-wrap justify-end items-center">
            <!-- TABLE ACTION COL-2: SEARCH & EXPORT AS CSV -->
            <div
              class="
                flex flex-wrap
                items-center
                justify-between
                ag-grid-table-actions-right
              "
            >
              <vs-input
                class="mb-4 md:mb-0 mr-4"
                v-model="searchQuery"
                @input="updateSearchQuery"
                placeholder="Search..."
              />
            </div>
            <vs-button
              v-if="
                this.permissionPageAccessArry.includes(
                  PermissionVariables.BudgetAdd
                ) || loginUserRole == 'Admin'
              "
              color="primary"
              class="mr-4 hover-btn"
              type="filled"
              @click="showAddBudgetModal"
              >Add New</vs-button
            >

            <vs-button
              v-if="
                (isDelete == 1 &&
                  is_data &&
                  this.permissionPageAccessArry.includes(
                    PermissionVariables.BudgetDelete
                  )) ||
                loginUserRole == 'Admin'
              "
              color="primary"
              class="mr-4 hover-btn"
              type="filled"
              @click="deleteBudgetModal"
              >Delete Budget</vs-button
            >

            <vx-tooltip text="Export Excel">
              <!-- Before Select Project -->
              <template v-if="!this.projectId">
                <vs-button class="px-4" @click.prevent="selectProject">
                  <span class="d-inline-flex pr-5 text-white">Export</span>
                  <img
                    src="@/assets/images/icons/export-excel.svg"
                    class="d-inline-flex"
                    height="20"
                    width="20"
                  />
                </vs-button>
              </template>

              <!-- After Select Project -->
              <template v-else>
                <a
                  :href="
                    excelurl +
                    `/v2/budget/excel/download?token=${temp_token}&project_id=${this.projectId}&organization_id=${organization_id}`
                  "
                  target="_blank"
                >
                  <vs-button
                    v-if="
                      this.permissionPageAccessArry.includes(
                        PermissionVariables.BudgetExport
                      ) || loginUserRole == 'Admin'
                    "
                    class="px-4"
                  >
                    <span class="d-inline-flex pr-5 text-white">Export</span>
                    <img
                      src="@/assets/images/icons/export-excel.svg"
                      class="d-inline-flex"
                      height="20"
                      width="20"
                    />
                  </vs-button>
                </a>
              </template>
            </vx-tooltip>
          </div>
        </div>

        <ag-grid-vue
          :gridOptions="gridOptions"
          class="ag-theme-material w-100 my-4 ag-grid-table"
          :columnDefs="columnDefs"
          :defaultColDef="defaultColDef"
          :rowClicked="onRowDataChanged"
          :rowData="budgetList"
          rowSelection="multiple"
          colResizeDefault="shift"
          :animateRows="true"
          :floatingFilter="false"
          :paginationPageSize="paginationPageSize"
          :pagination="true"
          :suppressPaginationPanel="true"
        ></ag-grid-vue>
        <vs-pagination
          :total="budgetPagination || 0"
          :max="maxPageNumbers"
          v-model="currentPage"
        />
      </vx-card>
    </div>
    <div class="centerx">
      <vs-popup
        class="holamundo editModal"
        title="Add New Budget Record"
        :active.sync="popupActive"
      >
        <form>
          <FocusLock>
            <div class="vx-row">
              <!-- LEFT SIDE -->
              <div class="col-12">
                <div class="vx-row mb-6">
                  <div class="col-md-4 text-right align-self-center">
                    <h5 class="mb-0">Budget Code</h5>
                  </div>
                  <div class="col-7">
                    <!-- Start ************* -->
                    <div>
                      <multiselect
                        v-validate="'required'"
                        v-model="form.budgetCode_value"
                        :options="budgetCodes"
                        :searchable="false"
                        :show-labels="false"
                        open-direction="bottom"
                        name="Budget Code"
                        placeholder="Select Budget Code"
                      ></multiselect>
                      <span
                        class="text-danger text-sm"
                        v-show="errors.has('Budget Code')"
                        >{{ errors.first("Budget Code") }}</span
                      >
                    </div>
                    <!-- ************* End -->
                  </div>
                </div>

                <div class="vx-row mb-6">
                  <div class="col-md-4 text-right align-self-center">
                    <h5 class="mb-0">Category</h5>
                  </div>
                  <div class="col-7">
                    <!-- Start ************* -->
                    <div>
                      <vue-simple-suggest
                        v-validate="'required'"
                        v-model="form.selectedCategory"
                        :list="categoryTemp"
                        display-attribute="category_name"
                        value-attribute="category_id"
                        :filter-by-query="true"
                        name="Category Name"
                        @select="onSuggestSelect"
                      ></vue-simple-suggest>
                      <span
                        class="text-danger text-sm"
                        v-show="errors.has('Category Name')"
                        >{{ errors.first("Category Name") }}</span
                      >
                    </div>
                    <!-- ************* End -->
                  </div>
                </div>

                <div class="vx-row mb-6">
                  <div class="col-md-4 text-right align-self-center">
                    <h5 class="mb-0">Subcategory</h5>
                  </div>
                  <div class="col-7">
                    <!-- Start ************* -->
                    <div>
                      <vue-simple-suggest
                        v-validate="'required'"
                        v-model="form.selectedSubCategory"
                        :list="SubcategoryTemp"
                        display-attribute="category_name"
                        value-attribute="category_id"
                        name="Subcategory Name"
                        :filter-by-query="true"
                      ></vue-simple-suggest>
                      <span
                        class="text-danger text-sm"
                        v-show="errors.has('Subcategory Name')"
                        >{{ errors.first("Subcategory Name") }}</span
                      >
                    </div>
                    <!-- ************* End -->
                  </div>
                </div>

                <div class="vx-row mb-6">
                  <div class="col-md-4 text-right align-self-center">
                    <h5 class="mb-0">Amount</h5>
                  </div>
                  <div class="col-7">
                    <div class>
                      <!-- v-validate="'required|regex:^[0-9]+'" -->
                      <vs-input
                        v-validate="'required'"
                        name="Budget Amount"
                        type="number"
                        v-model.number="form.budgetAmount"
                        class="w-full"
                      />
                      <span
                        class="text-danger text-sm"
                        v-show="errors.has('Budget Amount')"
                        >{{ errors.first("Budget Amount") }}</span
                      >
                    </div>
                  </div>
                </div>
                <div class="vx-row mb-6">
                  <div class="col-md-4 text-right align-self-center">
                    <h5 class="mb-0">Status</h5>
                  </div>
                  <div class="col-7">
                    <div class>
                      <b-form-checkbox
                        value="1"
                        unchecked-value="2"
                        v-model="form.budget_active"
                        name="check-button"
                        switch
                      ></b-form-checkbox>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- SUBMIT BUTTON -->
            <div class="vx-row">
              <div class="vx-col mx-auto">
                <!-- :disabled="submitStatus" -->
                <!-- @click.prevent="submitForm" -->
                <vs-button
                  :disabled="submitStatus"
                  type="filled"
                  class="mr-3"
                  @click.prevent="submitForm"
                  >Submit</vs-button
                >
                <!-- <vs-button color="warning" type="border" class @click.prevent="clearForm">Reset</vs-button> -->
              </div>
            </div>
          </FocusLock>
        </form>
      </vs-popup>
    </div>

    <!-- DELETE BUDGET POPUP -->
    <div class="demo-alignment">
      <vs-popup
        :button-close-hidden="true"
        class="holamundo p-2"
        title="Budget Status"
        :active.sync="popupActiveDeleteBudget"
      >
        <div class="text-center">
          <h5>Do you want to delete?</h5>
          <!-- SUBMIT BUTTON -->
          <div class="vx-row mt-5">
            <div class="vx-col mx-auto">
              <vs-button
                color="success"
                type="filled"
                @click="onChangeCheckbox($event)"
                class="mr-3"
                >Yes</vs-button
              >

              <vs-button color="danger" type="filled" @click="privStatus()"
                >No</vs-button
              >
            </div>
          </div>
        </div>
      </vs-popup>
    </div>
  </div>
</template>

<script>
import { AgGridVue } from "ag-grid-vue";
import { BASEURL } from "@/config/index.js";
import contacts from "../../ui-elements/ag-grid-table/data.json";
import DatetimePickerStartEnd from "@/views/components/extra-components/datetime-picker/DatetimePickerStartEnd.vue";
import BudgetService from "@/services/budgetService.js";
import CategoryService from "@/services/categoryService.js";
import SearchService from "@/services/searchService.js";
import viewBudgetDetails from "./viewBudgetDetails.vue";
import deleteBudget from "./deleteBudget.vue";
import viewExpenseDetails from "./viewExpenseDetails.vue";
import budgetStatus from "./budgetStatus.vue";
import isAllowedBudget from "./isAllowedBudget.vue";
import EditBudget from "./editBudget.vue";
import VueSimpleSuggest from "vue-simple-suggest";
import VueSuggest from "vue-simple-suggest/lib";
import ApexCharts from "apexcharts";
import PermissionVariables from "@/services/UserManagementVaraibles.js";
// import "vue-simple-suggest/dist/styles.css"; // Optional CSS
import "@/assets/scss/vuesax/extraComponents/agGridStyleOverride.scss";
import { mapState, mapGetters, mapActions } from "vuex";

export default {
  components: {
    AgGridVue,
    VueSuggest,
    VueSimpleSuggest,
    DatetimePickerStartEnd,
    "switch-component": {
      template: `<span>KOZO</span>`,
    },
  },
  props: ["listType"],
  data() {
    return {
      permissionPageAccessArry: window.localStorage.getItem(
        "permissionPageAccess"
      )
        ? localStorage.getItem("permissionPageAccess").split(",")
        : "",
      PermissionVariables: PermissionVariables,
      loginUserRole: window.localStorage.getItem("UserRole"),
      excelurl: BASEURL(),
      categories: [],
      subCategories: [],
      categoryTemp: [],
      SubcategoryTemp: [],
      totalPages: 0,
      overlayLoadingTemplate: null,
      submitStatus: true,
      searchPayload: {},
      archive: this.listType,
      departments: [],
      User: "Select User",
      switchValue: true,
      gridOptions: {},
      searchBudgetList: [],
      budgetCodes: [],
      maxPageNumbers: 7,
      popupActive: false,
      popupActiveDeleteBudget: false,
      gridApi: null,
      defaultColDef: {
        sortable: true,
        editable: false,
        resizable: true,
        suppressMenu: true,
      },
      project_id: null,
      form: {
        selectedCategory: "",
        selectedSubCategory: "",
        budgetCode_value: "",
        budgetAmount: "",
        budget_active: "1",
      },
      budgetCodesList: ["ATL", "BTL", "MUSIC", "OTHER", "POST", "VFX"],
      fromDate: null,
      toDate: null,
      configFromdateTimePicker: {
        minDate: new Date(),
        maxDate: null,
      },
      configTodateTimePicker: {
        minDate: null,
      },
      contacts: contacts,
      columnDefs: [],
      organization_id: "",
    };
  },
  beforeMount() {
    this.organization_id = localStorage.getItem("OrganizationID");
    if (this.organization_id != 35) {
      this.columnDefs = [
        {
          headerName: "Budget Code",
          field: "budget_code_num",
          filter: false,
          width: 150,
          suppressSizeToFit: true,
          cellRendererFramework: viewExpenseDetails,
        },
        {
          headerName: "Category",
          field: "category_name",
          filter: false,
          width: 250,
          headerTooltip: "Category",
          tooltipComponent: "customTooltip",
          cellRendererFramework: viewExpenseDetails,
        },
        {
          headerName: "Subcategory",
          field: "sub_category_name",
          filter: false,
          width: 250,
          cellRendererFramework: viewExpenseDetails,
        },

        {
          headerName: "Budget Amount",
          field: "formattedBudgetAmount",
          filter: false,
          width: 250,
          cellRendererFramework: viewExpenseDetails,
        },
        {
          headerName: "Contractual Unpaid",
          field: "contractAmount",
          filter: false,
          width: 250,
        },
        {
          headerName: "Daily Cost Report",
          field: "projectAmount",
          filter: false,
          width: 250,
        },
        {
          headerName: "Actual Expense",
          field: "actual_amount",
          filter: false,
          width: 250,
          cellRendererFramework: viewExpenseDetails,
        },
        {
          headerName: "Paid Amount",
          field: "paidAmount",
          filter: false,
          width: 250,
          // cellRendererFramework: viewExpenseDetails,
        },
        {
          headerName: "Credit Amount",
          field: "credit_amount",
          filter: false,
          width: 120,
          cellRenderer: function (params) {
            return `<div class="" style="color:red">${params.data.credit_amount} <div>`;
          },
          // cellRendererFramework: viewAllExpenseDetails
        },
        {
          headerName: "Balance Amount",
          field: "balance_amount",
          filter: false,
          width: 250,
          cellRendererFramework: viewExpenseDetails,
        },
        {
          headerName: "Allow to add expense",
          field: "",
          filter: false,
          width: 200,
          sortable: false,
          hide:
            (this.archive == "archive" ||
              !this.permissionPageAccessArry.includes(
                PermissionVariables.BudgetEdit
              )) &&
            this.loginUserRole != "Admin"
              ? true
              : false,
          cellStyle: { "text-align": "center" },
          cellRendererFramework: isAllowedBudget,
        },
        {
          headerName: "Status",
          field: "budget_active",
          filter: false,
          width: 120,
          sortable: false,
          hide:
            (this.archive == "archive" ||
              !this.permissionPageAccessArry.includes(
                PermissionVariables.BudgetEdit
              )) &&
            this.loginUserRole != "Admin"
              ? true
              : false,
          cellStyle: { "text-align": "center" },
          cellRendererFramework: budgetStatus,
        },
        {
          headerName: "Edit",
          field: "budget_id",
          filter: false,
          width: 120,
          hide:
            (this.archive == "archive" ||
              !this.permissionPageAccessArry.includes(
                PermissionVariables.BudgetEdit
              )) &&
            this.loginUserRole != "Admin"
              ? true
              : false,
          sortable: false,
          cellStyle: { "text-align": "center" },
          cellRendererFramework: EditBudget,
        },
        {
          headerName: "View",
          field: "budget_id",
          filter: false,
          width: 120,
          sortable: false,
          cellStyle: { "text-align": "center" },
          cellRendererFramework: viewBudgetDetails,
        },
      ];
    } else {
      this.columnDefs = [
        {
          headerName: "Budget Code",
          field: "budget_code_num",
          filter: false,
          width: 150,
          suppressSizeToFit: true,
          cellRendererFramework: viewExpenseDetails,
        },
        {
          headerName: "Category",
          field: "category_name",
          filter: false,
          width: 250,
          headerTooltip: "Category",
          tooltipComponent: "customTooltip",
          cellRendererFramework: viewExpenseDetails,
        },
        {
          headerName: "Subcategory",
          field: "sub_category_name",
          filter: false,
          width: 250,
          cellRendererFramework: viewExpenseDetails,
        },

        {
          headerName: "Budget Amount",
          field: "formattedInitialAmount",
          filter: false,
          width: 250,
          cellRendererFramework: viewExpenseDetails,
        },
        {
          headerName: "Revised Budget Amount",
          field: "formattedBudgetAmount",
          filter: false,
          width: 250,
          cellRendererFramework: viewExpenseDetails,
        },
        {
          headerName: "Variance Budget Amount",
          field: "formattedVarianceAmount",
          filter: false,
          width: 250,
          cellRendererFramework: viewExpenseDetails,
          cellStyle: (params) => {
            if (params.data.variance_amount > 0) {
              //mark police cells as red
              return { backgroundColor: "green" };
            } else if (params.data.variance_amount < 0) {
              return { backgroundColor: "red" };
            }
            return null;
          },
        },
        {
          headerName: "Contractual Unpaid",
          field: "contractAmount",
          filter: false,
          width: 250,
        },
        {
          headerName: "Daily Cost Report",
          field: "projectAmount",
          filter: false,
          width: 250,
        },
        {
          headerName: "Actual Expense",
          field: "actual_amount",
          filter: false,
          width: 250,
          cellRendererFramework: viewExpenseDetails,
        },
        {
          headerName: "Paid Amount",
          field: "actual_amount",
          filter: false,
          width: 250,
          // cellRendererFramework: viewExpenseDetails,
        },
        {
          headerName: "Credit Amount",
          field: "credit_amount",
          filter: false,
          width: 120,
          cellRenderer: function (params) {
            return `<div class="" style="color:red">${params.data.credit_amount} <div>`;
          },
          // cellRendererFramework: viewAllExpenseDetails
        },
        {
          headerName: "Balance Amount",
          field: "balance_amount",
          filter: false,
          width: 250,
          cellRendererFramework: viewExpenseDetails,
        },
        {
          headerName: "Allow to add expense",
          field: "",
          filter: false,
          width: 200,
          sortable: false,
          hide:
            (this.archive == "archive" ||
              !this.permissionPageAccessArry.includes(
                PermissionVariables.BudgetEdit
              )) &&
            this.loginUserRole != "Admin"
              ? true
              : false,
          cellStyle: { "text-align": "center" },
          cellRendererFramework: isAllowedBudget,
        },
        {
          headerName: "Status",
          field: "budget_active",
          filter: false,
          width: 120,
          sortable: false,
          hide:
            (this.archive == "archive" ||
              !this.permissionPageAccessArry.includes(
                PermissionVariables.BudgetEdit
              )) &&
            this.loginUserRole != "Admin"
              ? true
              : false,
          cellStyle: { "text-align": "center" },
          cellRendererFramework: budgetStatus,
        },
        {
          headerName: "Edit",
          field: "budget_id",
          filter: false,
          width: 120,
          hide:
            (this.archive == "archive" ||
              !this.permissionPageAccessArry.includes(
                PermissionVariables.BudgetEdit
              )) &&
            this.loginUserRole != "Admin"
              ? true
              : false,
          sortable: false,
          cellStyle: { "text-align": "center" },
          cellRendererFramework: EditBudget,
        },
        {
          headerName: "View",
          field: "budget_id",
          filter: false,
          width: 120,
          sortable: false,
          cellStyle: { "text-align": "center" },
          cellRendererFramework: viewBudgetDetails,
        },
        // {
        //   headerName: "Delete",
        //   field: "budget_id",
        //   filter: false,
        //   width: 120,
        //   sortable: false,
        //   cellStyle: { "text-align": "center" },
        //   cellRendererFramework: deleteBudget
        // }
      ];
    }
    // this.frameworkComponents = { customTooltip: CustomTooltip };
    // this.setColums();
    this.gridOptions = {
      getRowStyle(params) {
        let temp_str = params.data.actual_amount;
        if (temp_str.length > 3) {
          temp_str = params.data.actual_amount.replace(/\,/g, "");
        }

        let temp_str1 = params.data.budget_amount;
        if (temp_str1.length > 3) {
          temp_str1 = params.data.budget_amount.replace(/\,/g, "");
        }
        if (parseInt(temp_str1) < parseInt(temp_str)) {
          return { background: "red" };
        } else {
          return null;
        }
      },
    };
  },
  watch: {
    "$store.state.windowWidth"(val) {
      if (val <= 576) {
        this.maxPageNumbers = 4;
        this.gridOptions.columnApi.setColumnPinned("email", null);
      } else this.gridOptions.columnApi.setColumnPinned("email", "left");
    },
    form: {
      handler: function (newVal, oldVal) {
        let len = Object.keys(this.form).length;
        if (
          len ===
          Object.keys(newVal).filter(
            (x, index) =>
              newVal[x] &&
              newVal[x] !== undefined &&
              String(newVal[x]).trim().length > 0
          ).length
        ) {
          this.submitStatus = false;
        } else {
          this.submitStatus = true;
        }
      },
      deep: true,
    },
  },
  computed: {
    // is_data() {
    //   // this.showExtraField(this.$store.state.budgetObject.ExtraField);
    //   // return Object.keys(this.budgetList).length > 0;
    // },
    budgetList() {
      if (this.projectId) {
        if (this.searchQuery != null && this.searchQuery != "") {
          return this.searchBudgetList;
        } else {
          return this.$store.getters.getbudgetlist;
        }
      } else {
        return [];
      }
    },
    isDelete() {
      return this.$store.state.is_delete;
    },
    searchQuery: {
      get() {
        return this.$store.state.selectedCode;
      },
      set(value) {
        this.$store.dispatch("selectedCode", value);
      },
    },
    budgetPagination() {
      return this.$store.state.budgetObject.pagination.last_page;
    },
    projectId() {
      return this.$store.state.budgetObject.projectId;
    },
    paginationPageSize() {
      if (this.gridApi) return this.gridApi.paginationGetPageSize();
      else return 100;
    },
    currentPage: {
      get() {
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1;
        else return 1;
      },
      set(val) {
        if (val) {
          // this.getBudgetList(val);
          this.$store.dispatch("SetCurrentPageBudget", {
            page: val,
          });
          return val;
        } else return 1;
      },
    },
  },
  methods: {
    // ...mapActions(["clearBudgetList"]),

    showExtraField(ExtraField) {
      if (ExtraField) {
        this.columnDefs = [
          {
            headerName: "Budget Code",
            field: "budget_code_num",
            filter: false,
            width: 150,
            suppressSizeToFit: true,
            cellRendererFramework: viewExpenseDetails,
          },
          {
            headerName: "Fixed/Variable",
            field: "fixvar",
            filter: false,
            width: 250,
            cellRendererFramework: viewExpenseDetails,
          },
          {
            headerName: "Category",
            field: "category_name",
            filter: false,
            width: 250,
            headerTooltip: "Category",
            tooltipComponent: "customTooltip",
            cellRendererFramework: viewExpenseDetails,
          },
          {
            headerName: "Subcategory",
            field: "sub_category_name",
            filter: false,
            width: 250,
            cellRendererFramework: viewExpenseDetails,
          },

          {
            headerName: "Budget Amount",
            field: "formattedInitialAmount",
            filter: false,
            width: 250,
            cellRendererFramework: viewExpenseDetails,
          },
          {
            headerName: "Nos",
            field: "nos",
            filter: false,
            width: 250,
            cellRendererFramework: viewExpenseDetails,
          },
          {
            headerName: "Period",
            field: "period",
            filter: false,
            width: 250,
            cellRendererFramework: viewExpenseDetails,
          },
          {
            headerName: "Rate",
            field: "rate",
            filter: false,
            width: 250,
            cellRendererFramework: viewExpenseDetails,
          },
          {
            headerName: "Revised Budget Amount",
            field: "formattedBudgetAmount",
            filter: false,
            width: 250,
            cellRendererFramework: viewExpenseDetails,
          },
          {
            headerName: "Variance Budget Amount",
            field: "formattedVarianceAmount",
            filter: false,
            width: 250,
            cellRendererFramework: viewExpenseDetails,
            cellStyle: (params) => {
              if (params.data.variance_amount > 0) {
                //mark police cells as red
                return { backgroundColor: "green" };
              } else if (params.data.variance_amount < 0) {
                return { backgroundColor: "red" };
              }
              return null;
            },
          },
          {
            headerName: "Contractual Unpaid",
            field: "contractAmount",
            filter: false,
            width: 250,
          },
          {
            headerName: "Daily Cost Report",
            field: "projectAmount",
            filter: false,
            width: 250,
          },
          {
            headerName: "Actual Expense",
            field: "actual_amount",
            filter: false,
            width: 250,
            cellRendererFramework: viewExpenseDetails,
          },
          {
            headerName: "Paid Amount",
            field: "paidAmount",
            filter: false,
            width: 250,
            // cellRendererFramework: viewExpenseDetails,
          },
          {
            headerName: "Credit Amount",
            field: "credit_amount",
            filter: false,
            width: 120,
            cellRenderer: function (params) {
              return `<div class="" style="color:red">${params.data.credit_amount} <div>`;
            },
            // cellRendererFramework: viewAllExpenseDetails
          },
          {
            headerName: "Balance Amount",
            field: "balance_amount",
            filter: false,
            width: 250,
            cellRendererFramework: viewExpenseDetails,
          },
          {
            headerName: "Allow to add expense",
            field: "",
            filter: false,
            width: 200,
            sortable: false,
            hide:
              (this.archive == "archive" ||
                !this.permissionPageAccessArry.includes(
                  PermissionVariables.BudgetEdit
                )) &&
              this.loginUserRole != "Admin"
                ? true
                : false,
            cellStyle: { "text-align": "center" },
            cellRendererFramework: isAllowedBudget,
          },
          {
            headerName: "Status",
            field: "budget_active",
            filter: false,
            width: 120,
            sortable: false,
            hide:
              (this.archive == "archive" ||
                !this.permissionPageAccessArry.includes(
                  PermissionVariables.BudgetEdit
                )) &&
              this.loginUserRole != "Admin"
                ? true
                : false,
            cellStyle: { "text-align": "center" },
            cellRendererFramework: budgetStatus,
          },
          {
            headerName: "Edit",
            field: "budget_id",
            filter: false,
            width: 120,
            hide:
              (this.archive == "archive" ||
                !this.permissionPageAccessArry.includes(
                  PermissionVariables.BudgetEdit
                )) &&
              this.loginUserRole != "Admin"
                ? true
                : false,
            sortable: false,
            cellStyle: { "text-align": "center" },
            cellRendererFramework: EditBudget,
          },
          {
            headerName: "View",
            field: "budget_id",
            filter: false,
            width: 120,
            sortable: false,
            cellStyle: { "text-align": "center" },
            cellRendererFramework: viewBudgetDetails,
          },
          // {
          //   headerName: "Delete",
          //   field: "budget_id",
          //   filter: false,
          //   width: 120,
          //   sortable: false,
          //   cellStyle: { "text-align": "center" },
          //   cellRendererFramework: deleteBudget
          // }
        ];
      }
    },

    onRowSelected(event) {
      window.alert(
        "row " + event.node.data.athlete + " selected = " + event.node.selected
      );
    },
    selectProject: function () {
      this.$vs.notify({
        time: 5000,
        title: "Select Project",
        iconPack: "feather",
        icon: "check_box",
        color: "warning",
      });
    },
    onRowDataChanged(e) {
      console.log("e :", e);
    },
    onChangeStatus: function () {
      console.log("object");
    },
    updateSearchQuery(val) {
      this.searchPayload = {
        project_id: this.projectId,
      };
      // this.gridApi.setQuickFilter(val);
      SearchService.getAllSerchResult(val, "budget/project", this.searchPayload)
        .then((response) => {
          const { data } = response;

          if (data.Status) {
            this.searchBudgetList = data.data;
          } else {
            this.searchBudgetList = [];
            // this.$vs.notify({
            //   time: 5000,
            //   title: "Search data not found",
            //   text: data.Message,
            //   iconPack: "feather",
            //   icon: "check_box",
            //   color: "warning",
            // });
          }
        })
        .catch((error) => {
          this.$vs.notify({
            time: 5000,
            title: "Data Not Found",
            text: error.Message,
            iconPack: "feather",
            icon: "check_box",
            color: "warning",
          });
        });
    },
    onFromChange(selectedDates, dateStr, instance) {
      this.$set(this.configTodateTimePicker, "minDate", dateStr);
    },
    onToChange(selectedDates, dateStr, instance) {
      this.$set(this.configFromdateTimePicker, "maxDate", dateStr);
    },
    showAddBudgetModal() {
      this.getCategoryList();
      this.getBudgetCodeList();
      let isProjectSelected = this.projectId;
      if (isProjectSelected) {
        this.popupActive = true;
      } else {
        this.$vs.notify({
          time: 5000,
          title: "Select Project",
          iconPack: "feather",
          icon: "check_box",
          color: "warning",
        });
      }
      // this.getCompanyList();
    },
    deleteBudgetModal: function () {
      let isProjectSelected = this.projectId;
      if (isProjectSelected) {
        this.popupActiveDeleteBudget = true;
      } else {
        this.$vs.notify({
          time: 5000,
          title: "Select Project",
          iconPack: "feather",
          icon: "check_box",
          color: "warning",
        });
      }
    },

    onChangeCheckbox: function (e, value) {
      this.$vs.loading();
      let id = this.projectId;
      BudgetService.deleteBudget(id)
        .then((response) => {
          this.$vs.loading.close();
          const { data } = response;
          if (data.Status) {
            this.$vs.notify({
              time: 5000,
              title: "Updated!",
              text: data.Message,
              iconPack: "feather",
              icon: "check_box",
              color: "success",
            });
            setTimeout(() => {
              eventBus.$emit("refreshTableBudgetList", data);
            }, 1);
            this.popupActiveDeleteBudget = false;
          } else {
            this.$vs.loading.close();
            this.$vs.notify({
              time: 5000,
              title: "Error!",
              text: data.Message,
              iconPack: "feather",
              icon: "check_box",
              color: "warning",
            });
            this.popupActiveDeleteBudget = false;
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          this.$vs.notify({
            time: 5000,
            title: "Error!",
            text: error.Message,
            iconPack: "feather",
            icon: "check_box",
            color: "warning",
          });
        });
    },
    privStatus: function () {
      this.popupActiveDeleteBudget = false;
    },
    getBudgetCodeList: function () {
      let id = this.projectId;
      BudgetService.getBudgetCodes(id)
        .then((response) => {
          const { data } = response;
          if (data.Status) {
            if (data.data.length > 0) {
              this.budgetCodes = data.data;
            } else {
              this.budgetCodes = this.budgetCodesList;
            }
          } else {
            this.$vs.notify({
              time: 5000,
              title: "Data Not Found",
              text: data.Message,
              iconPack: "feather",
              icon: "check_box",
              color: "warning",
            });
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          this.$vs.notify({
            time: 5000,
            title: "Error!",
            text: error.Message,
            iconPack: "feather",
            icon: "check_box",
            color: "warning",
          });
        });
    },
    getCategoryList: function () {
      let id = this.projectId;
      CategoryService.getAllcategoriesByProject(id)
        .then((response) => {
          const { data } = response;
          if (data.Status == true || data.Status == "true") {
            this.categories = data.data.filter((x) => {
              if (String(x.parent_id) === "0") {
                return x;
              }
            });
            // this.subCategories = data.data.filter(x => {
            //   if (String(x.parent_id) !== "0") {
            //     return x;
            //   }
            // });
            this.categoryTemp = this.categories;
            // .filter(x => {
            //   return x.category_name;
            // });
          } else {
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          this.$vs.notify({
            time: 5000,
            title: "Error!",
            text: error.Message,
            iconPack: "feather",
            icon: "check_box",
            color: "warning",
          });
        });
    },
    onSuggestSelect: function (selected) {
      // this.getSubcategoryList(projectId);
      this.getSubcategoryList(selected.category_id || null);
    },
    getSubcategoryList: function (parent_id) {
      let ID = this.projectId;
      CategoryService.getSubCategoriesByParentCategory(parent_id, ID)
        .then((response) => {
          const { data } = response;
          if (data.Status) {
            this.subCategories = data.data;
            this.SubcategoryTemp = this.subCategories.filter((x) => {
              return x.category_name;
            });
          }
        })
        .catch((error) => {});
    },
    submitForm: function () {
      this.$validator.validateAll().then((result) => {
        this.submitStatus = false;
        if (result) {
          this.$vs.loading();
          let payload = {
            budget_code: this.form.budgetCode_value,
            category_name: this.form.selectedCategory,
            sub_category_name: this.form.selectedSubCategory,
            budget_amount: this.form.budgetAmount,
            budget_active: this.form.budget_active,
            project_id: this.projectId,
          };

          BudgetService.addBudget(payload)
            .then((response) => {
              this.$vs.loading.close();
              const { data } = response;
              if (data.Status) {
                this.$vs.notify({
                  time: 5000,
                  title: "Added Successfully",
                  text: data.Message,
                  iconPack: "feather",
                  icon: "check_box",
                  color: "success",
                });
                // event
                // this.$store.dispatch("getBudgetList", this.project_id);
                this.popupActive = false;
                setTimeout(() => {
                  eventBus.$emit("refreshBudgetTable", data);
                }, 1);
                this.clearForm();
              } else {
                this.$vs.loading.close();
                this.$vs.notify({
                  time: 5000,
                  title: "Error!",
                  text: data.Message,
                  iconPack: "feather",
                  icon: "check_box",
                  color: "warning",
                });
              }
            })
            .catch((error) => {
              this.$vs.loading.close();
              this.$vs.notify({
                time: 5000,
                title: "Error!",
                text: error.Message,
                iconPack: "feather",
                icon: "check_box",
                color: "warning",
              });
            });
          // if form have no errors
          // alert("form submitted!");
        } else {
          // form have errors
        }
      });
    },

    clearForm: function () {
      Object.keys(this.form).map((x, index) => {
        /_active/.test(x) ? (this.form[x] = "1") : (this.form[x] = "");
      });
      // this.description = "";
      // this.value = [];
      this.$validator.reset();
    },

    getBudgetList: function (currentPage) {
      // let filter = {
      //   page: currentPage
      // };
      // BudgetService.getAllBudget(this.projectId)
      //   .then(response => {
      //     const { data } = response;
      //     if (data.err || data.Status == false) {
      //     } else {
      //       this.departments = data.data;
      //       this.totalPages = data.pagination.last_page;
      //       this.gridApi.sizeColumnsToFit();
      //     }
      //   })
      //   .catch(error => {
      //     console.log(error);
      //   });
    },
  },
  mounted() {
    if (!this.projectId) {
    }

    // if (this.checkPermission(["budget", "GET"])) {
    //   this.getBudgetList(this.currentPage);
    // }
    // this.gridApi.sizeColumnsToFit();
    this.gridApi = this.gridOptions.api;

    // this.$nextTick(() => {
    // setTimeout(() => {
    //   eventBus.$on("SetBudgetReportData", e => {
    //     this.searchQuery = e;
    //     this.gridApi.setQuickFilter(e);
    //     this.$forceUpdate();
    //   });
    // }, 5000);
    // });
  },
};
</script>

<style>
pre.selected {
  margin: 8px 0;
  height: 295px;
  overflow-x: scroll;
  overflow-y: scroll;
  border: 1px solid #cde;
  border-radius: 4px;
}
.v-model-event {
  background-color: white;
  border: 1px solid #cde;
  border-radius: 4px;
}
.v-model-event.selected {
  color: red;
}
.v-model-event:hover {
  border: 1px solid #2874d5;
  background-color: #2874d5;
  color: white;
}
.vue-simple-suggest .suggest-item .text {
  display: inline-block;
  line-height: 1;
  vertical-align: text-bottom;
  overflow: hidden;
  max-width: 72%;
  text-overflow: ellipsis;
}
.vue-simple-suggest .suggest-item .text span {
  white-space: nowrap;
}
.vue-simple-suggest .suggest-item button {
  float: right;
  line-height: 1;
  margin-left: 4px;
}
.vue-simple-suggest-enter-active.suggestions,
.vue-simple-suggest-leave-active.suggestions {
  transition: opacity 0.2s;
}
.vue-simple-suggest-enter.suggestions,
.vue-simple-suggest-leave-to.suggestions {
  opacity: 0 !important;
}
</style>
